import React from 'react'

// @ts-ignore
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'

const Privacy: React.FC<PageProps> = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle }
    }
  },
  location
}: {
  data: { site: { siteMetadata: { title: string } } }
  location: Location
}) => (
  <Layout location={location}>
    <div>
      <Helmet>
        <title>
          Privacy policy
          {` | ${siteTitle}`}
        </title>
      </Helmet>

      <h1>Privacy policy</h1>
      <p>
        The provider of this website is taking the security of your personal
        data serious. I handle your data as confidential and follow the legal
        rules and the rules of this privacy policy. In general, the usage of
        this website is possible without providing any personal data. If I do
        collect personal data (lie e.g. name, address or e-mail address) I am
        only collecting data you are voluntarily providing. By no means, I sell
        your personal data or provide your data to third parties without your
        consent.
      </p>
      <p>
        Last, but not least I want to make you aware that transmitting data via
        the Internet (e.g. by sending an e-mail) is prone to some security
        risks. Absolute protection against data access by third parties is
        practically impossible.
      </p>

      <h2>Cookies</h2>
      <p>
        This website is using cookies. Cookies do no harm to your computer and
        do not contain any virus. Cookies help to customize our offers and help
        to increase usability, efficiency and security. Cookies are small text
        files, which get transferred to your computer and stored by your
        browser. Most cookies we use are so-called “session cookies”. These
        cookies get deleted once you have ended your visit to this site. Other
        cookies get stored on your device until you delete them. These cookies
        help us to recognize your browser next time you visit our website. The
        settings of your browser allow you to get informed each time a cookie
        gets placed and you can then decide whether you allow this placement or
        not. Furthermore, you could restrict the acceptance of cookies and
        enable automatic deletion of cookies each time you close your browser.
        If you generally disable the acceptance of cookies, the functionality of
        this website might be restricted.
      </p>
      <p>
        Many of the online-advertising-cookies of companies can be administrated
        on the{' '}
        <a href="http://www.aboutads.info/choices/">US american webpage</a> or
        the{' '}
        <a href="http://www.youronlinechoices.com/uk/your-ad-choices/">
          EU webpage
        </a>
        .
      </p>

      <h2>Data analysis: Clicky Web Analytics</h2>
      <p>
        This website uses Clicky, a web analysis service by Roxr Software Ltd.
        Clicky uses cookies. The information generated by the cookie about your
        use of this website is usually transferred to a server of Roxr Software
        Ltd and stored there in an anonymized form.
      </p>
      <p>
        On my behalf Roxr Software uses this information to interpret your
        Website use, to assemble reports on the Website activities, and to
        render further Website- and internet-related services to me. The legal
        basis of the use of this service is my legitimate interest in the
        traceability of the use and, in turn, optimizing my services pertaining
        to art. 6 para 1 lit. f GDPR.
      </p>
      <p>
        You can prevent storing of the cookies through a respective setting in
        your browser software. You might not be able to use all functions on the
        Website then though.
      </p>
      <p>
        Here you'll find a{' '}
        <a href="https://clicky.com/terms/privacy">
          precise explanation how Clicky Web Analytics works and its cookies
        </a>
        .
      </p>
      <p>
        You can also{' '}
        <a href="https://clicky.com/optout">
          opt out of tracking by using Clicky's opt-out page
        </a>
        .
      </p>
    </div>
  </Layout>
)

export default Privacy

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
